<template>
  <CreateUpdateTemplate
    :customClass="'supplier-create create-form'"
    v-if="getPermission('supplier:create')"
  >
    <template v-slot:header-title>
      <h1 class="form-title d-flex">Create a new Supplier</h1>
    </template>
    <template v-slot:header-action>
      <v-btn
        v-on:click="goBack"
        :disabled="formLoading"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        v-on:click="onSubmit"
        color="cyan"
      >
        Save Supplier
      </v-btn>
    </template>
    <template v-slot:body>
      <v-form
        ref="supplierForm"
        v-model="formValid"
        lazy-validation
        v-on:submit.stop.prevent="onSubmit"
      >
        <v-tabs
          v-model="supplierTab"
          background-color="transparent"
          centered
          color="cyan"
          fixed-tabs
          class="custom-tab-transparent"
        >
          <template v-for="(tab, index) in tabs">
            <v-tab
              class="font-size-16 font-weight-600 px-8"
              v-if="getPermission(tab.permission + ':create')"
              :key="index"
              :href="'#tab-' + tab.key"
            >
              <v-icon color="grey darken-4" left>{{ tab.icon }}</v-icon>
              {{ tab.title }}
            </v-tab>
          </template>
        </v-tabs>
        <v-tabs-items v-model="supplierTab">
          <v-tab-item :value="'tab-details'">
            <ContactDetails
              v-on:saveContactDetail="updateContactDetail"
            ></ContactDetails>
          </v-tab-item>
          <v-tab-item
            v-if="getPermission('contact-person:create')"
            :value="'tab-contact-person'"
          >
            <ContactPersons
              v-bind:detail="contactDetail"
              v-on:saveContactPerson="updateContactPerson"
            ></ContactPersons>
          </v-tab-item>
          <v-tab-item :value="'tab-billing'">
            <BillingAddress
              v-on:saveBillingAddress="updateBillingAddress"
            ></BillingAddress>
          </v-tab-item>
        </v-tabs-items>
      </v-form>
    </template>
  </CreateUpdateTemplate>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  POST,
  PUT,
  CLEAR_ERROR,
  GET,
} from "@/core/services/store/request.module";
import ContactDetails from "@/view/pages/supplier/create-or-update/Contact-Details.vue";
import ContactPersons from "@/view/pages/supplier/create-or-update/Contact-Persons.vue";
import BillingAddress from "@/view/pages/supplier/create-or-update/Billing-Address.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";

export default {
  mixins: [CommonMixin],
  name: "supplier-create",
  title: "Create Supplier",
  data() {
    return {
      billingAddress: {},
      contactDetail: {},
      contactPerson: [],
      notification: {},
      tabs: [
        {
          title: "Contact Details",
          icon: "mdi-phone",
          key: "details",
          permission: "supplier",
        },
        {
          title: "Contact Persons",
          icon: "mdi-account-multiple",
          key: "contact-person",
          permission: "contact-person",
        },
        {
          title: "Company Address",
          icon: "mdi-credit-card",
          key: "billing",
          permission: "supplier",
        },
      ],
      supplierTab: "tab-details",
      isSupplierCreated: false,
      isAddressCreated: false,
      supplierId: 0,
      options: {},
    };
  },
  components: {
    ContactDetails,
    ContactPersons,
    BillingAddress,
    CreateUpdateTemplate,
  },
  watch: {
    supplierTab() {
      const _this = this;
      setTimeout(function () {
        _this.$refs.supplierForm.validate();
      }, 200);
    },
    formValid(param) {
      if (param) {
        this.$store.dispatch(CLEAR_ERROR, {});
      }
    },
  },
  methods: {
    updateBillingAddress(param) {
      this.billingAddress = param;
    },
    updateContactDetail(param) {
      this.contactDetail = param;
    },
    updateContactPerson(param) {
      this.contactPerson = param;
    },
    getOptions() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "supplier/options" })
        .then(({ data }) => {
          _this.options = data.options;
        })
        .catch((error) => {
          _this.logError(error);
        });
      /*.finally(() => {
          _this.$refs.supplierForm.validate();
        });*/
    },
    createOrUpdatedAddress(supplier) {
      return new Promise((resolve, reject) => {
        try {
          if (supplier) {
            const _this = this;
            const addressArr = [];
            if (_this.lodash.isEmpty(_this.billingAddress) === false) {
              addressArr.push(_this.billingAddress);
            }

            if (_this.lodash.isEmpty(addressArr) === false) {
              const formData = { address: addressArr, type: "supplier" };
              _this.$store
                .dispatch(PUT, { url: "address/" + supplier, data: formData })
                .then((response) => {
                  resolve(response);
                })
                .catch((error) => {
                  reject(error);
                });
            } else {
              resolve(true);
            }
          } else {
            resolve(true);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    makeDefaultPerson() {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          let emailData = _this.lodash.filter(
            _this.contactDetail.details,
            function (data) {
              return data.type === 1;
            }
          );
          let contactData = _this.lodash.filter(
            _this.contactDetail.details,
            function (data) {
              return data.type === 2;
            }
          );
          let firstEntry = {
            id: null,
            title: _this.contactDetail.salutation,
            first_name: _this.contactDetail.first_name,
            last_name: _this.contactDetail.last_name,
            will_notified: 1,
            position: null,
            display_name: _this.createDisplayName(),
            primary_email: _this.lodash.head(emailData)
              ? _this.lodash.head(emailData).value
              : null,
            primary_phone: _this.lodash.head(contactData)
              ? _this.lodash.head(contactData).value
              : null,
            default: 1,
          };
          resolve([firstEntry]);
        } catch (error) {
          reject(error);
        }
      });
    },
    createDisplayName() {
      const _this = this;
      let firstname = "";
      if (_this.contactDetail.first_name) {
        firstname = _this.contactDetail.first_name.toLowerCase();
      }
      let lastname = "";
      if (_this.contactDetail.last_name) {
        lastname = _this.contactDetail.last_name.toLowerCase();
      }
      return firstname + lastname;
    },
    async onSubmit() {
      const _this = this;
      try {
        const formErrors = _this.validateForm(_this.$refs.supplierForm);
        if (formErrors.length) {
          for (let i = 0; i < formErrors.length; i++) {
            ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
          }
          return false;
        }

        if (_this.lodash.isEmpty(_this.billingAddress)) {
          _this.supplierTab = "tab-billing";
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("Company address is required")
          );
          return false;
        }

        if (!_this.$refs.supplierForm.validate()) {
          return false;
        }

        //_this.formLoading = true;
        _this.$store.dispatch(CLEAR_ERROR, {});

        if (_this.lodash.isEmpty(_this.contactPerson)) {
          _this.contactPerson = await _this.makeDefaultPerson();
        }

        let formData = _this.contactDetail;

        if (_this.lodash.isEmpty(_this.contactDetail.profile_logo) === false) {
          formData.profile_logo = _this.contactDetail.profile_logo.id;
        }

        if (_this.lodash.isEmpty(_this.contactPerson) === false) {
          formData.persons = _this.contactPerson;
        }

        formData.lead =
          _this.lodash.toSafeInteger(_this.$route.query.lead) || null;
        formData.proposal =
          _this.lodash.toSafeInteger(_this.$route.query.proposal) || null;
        formData.client_reminders = 0;
        formData.client_follow_up_emails = 0;
        formData.print_email_on_work_order = 0;
        formData.supplier_remark = _this.options.remarks;

        if (_this.lodash.isEmpty(_this.notification) === false) {
          formData.client_reminders = _this.lodash.toSafeInteger(
            _this.notification.client_reminders
          );
          formData.client_follow_up_emails = _this.lodash.toSafeInteger(
            _this.notification.client_follow_up_emails
          );
          formData.print_email_on_work_order = _this.lodash.toSafeInteger(
            _this.notification.print_email_on_work_order
          );
          formData.supplier_remark = _this.lodash.toString(
            _this.notification.supplier_remark
          );
        }

        _this.formLoading = true;
        try {
          if (!_this.isSupplierCreated) {
            let supplierObject = await _this.$store.dispatch(POST, {
              url: "supplier",
              data: formData,
            });
            _this.supplierId = supplierObject.data.id;
            _this.isSupplierCreated = true;
          }
        } catch (error) {
          ErrorEventBus.$emit(
            "update:error",
            "Supplier is not created. Please try again."
          );
        }

        try {
          if (!_this.isAddressCreated) {
            await _this.createOrUpdatedAddress(_this.supplierId);
            _this.isAddressCreated = true;
          }
        } catch (error) {
          _this.formLoading = false;
          ErrorEventBus.$emit(
            "update:error",
            "Property or Company Address is not created. Please try again."
          );
          return false;
        }

        _this.formLoading = false;

        if (_this.isSupplierCreated) {
          _this.$router.push(
            _this.getDefaultRoute("supplier.detail", {
              params: { id: _this.supplierId },
            })
          );
        }
      } catch (error) {
        ErrorEventBus.$emit("update:error", InitializeError(error));
      }
    },
  },
  mounted() {
    this.getOptions();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Supplier", route: "supplier" },
      { title: "Create" },
    ]);
  },
};
</script>
